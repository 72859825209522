import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { RemoveAssessment } from "src/services/third-party-risk/assessments/vendor-assessment";

const DeleteAssessment = ({ reviewID }: { reviewID: string }) => {
  const [show, setShow] = useState<boolean>(false);

  const removeAssessment = RemoveAssessment();
  const deleting = removeAssessment.status === "loading";

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={deleting}
        className="flex items-center place-self-end p-2 w-max dark:disabled:bg-filter dark:bg-reset dark:hover:bg-reset/60 duration-100 rounded-full"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-5">
          <h4 className="text-base text-center mb-3">
            Are you sure you want to delete this review?
          </h4>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={deleting}
              className="red-button"
              onClick={() => {
                removeAssessment.mutate({
                  reviewID: reviewID,
                });
                handleOnClose();
              }}
            >
              Delete review
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default DeleteAssessment;
