import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { RemoveVendor } from "../../../../services/third-party-risk/vendors/vendors";

const DeleteVendor = ({ vendorID }: { vendorID: string }) => {
  const [show, setShow] = useState<boolean>(false);

  const removeVendor = RemoveVendor(vendorID);

  const deleting = removeVendor.status === "loading";

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={deleting}
        className="red-trash-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-5">
          <h4 className="text-base text-center mb-3">
            Are you sure you want to delete this vendor?
          </h4>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={deleting}
              className="red-button"
              onClick={() => {
                removeVendor.mutate({});
                handleOnClose();
              }}
            >
              Delete vendor
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default DeleteVendor;
