import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import FileInput from "src/components/Input/FileInput";
import RegularInput from "src/components/Input/RegularInput";
import { AddGlobalQuestionSet } from "src/services/third-party-risk/questions/global-questions";

const NewGlobalQuestionSet = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    file: "",
    file_uri: "",
  });

  const addQuestionSet = AddGlobalQuestionSet();

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            file: "",
            file_uri: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Upload Question Set</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Upload Question Set
          </h3>
          <RegularInput
            label="File URL (optional)"
            keyName="file_uri"
            inputs={inputs}
            setInputs={setInputs}
          />
          <FileInput
            label="Question Set"
            keyName="file"
            types={["pdf", "docx", "xlsx"]}
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.file === "" && inputs.file_uri === ""}
              className="blue-button"
              onClick={() => {
                const formData = new FormData();

                formData.append("file", inputs.file);
                if (inputs.file_uri)
                  formData.append("file_uri", inputs.file_uri);

                addQuestionSet.mutate({
                  formData: formData,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default NewGlobalQuestionSet;
