import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { attributeColors } from "src/constants/general";
import { AddVendor } from "src/services/third-party-risk/vendors/vendors";
import {
  AddVendorLogo,
  GetVendorsAndPartners,
} from "../../../../services/third-party-risk/vendors/vendors";
import { KeyStringVal } from "../../../../types/general";
import UserInput from "../../../../components/Input/UserInput";
import NumericFilter from "src/components/Filter/General/NumericFilter";
import FileInput from "src/components/Input/FileInput";
import { queryClient } from "src/App";

const NewVendor = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    inherent_risk: "critical",
    address: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    vendor_type: "",
    vendor_manager: "",
    vendor_tier: "",
    rank_tier: "",
    website: "",
    duns_number: "",
    status: "",
    logo: "",
    is_publicly_traded: false,
    notes: "",
  });
  const [numEmployees, setNumEmployees] = useState<number>(0);
  const [valid, setValid] = useState<boolean>(true);

  const { data: vendors } = GetVendorsAndPartners(1);
  const addVendor = AddVendor();
  const addVendorLogo = AddVendorLogo();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addVendor.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setNumEmployees(0);
          setInputs({
            name: "",
            inherent_risk: "critical",
            address: "",
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            vendor_type: "",
            vendor_manager: "",
            vendor_tier: "",
            rank_tier: "",
            website: "",
            duns_number: "",
            status: "",
            logo: "",
            is_publicly_traded: false,
            notes: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Vendor</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Vendor</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Vendor"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Vendor Type"
              keyName="vendor_type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Address"
              keyName="address"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Contact Name"
              keyName="contact_name"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Contact Email"
              keyName="contact_email"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Contact Phone"
              keyName="contact_phone"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Website"
              keyName="website"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="DUNS Number"
              keyName="duns_number"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
          />
          <section className="flex flex-wrap items-center gap-10">
            <UserInput
              label="Vendor Manager"
              keyName="vendor_manager"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <NumericFilter
              label="Number of Employees"
              value={numEmployees}
              setValue={setNumEmployees}
            />
            <article className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={inputs.is_publicly_traded}
                className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-checkbox dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-checkbox focus:ring-opacity-50"
                onChange={() => {
                  setInputs({
                    ...inputs,
                    is_publicly_traded: !inputs.is_publicly_traded,
                  });
                }}
              />
              <label>Publicly traded</label>
            </article>
          </section>
          <section className="grid gap-2">
            <h4>Status</h4>
            <ul className="flex items-center gap-2">
              {["active", "inactive"].map((status) => {
                return (
                  <li
                    key={status}
                    className={`cursor-pointer capitalize ${
                      attributeColors[status]
                    } dark:hover:bg-signin/30 duration-100 ${
                      inputs.status === status
                        ? "ring-2 ring-offset-2 ring-offset-blue-500"
                        : ""
                    }`}
                    onClick={() => setInputs({ ...inputs, status: status })}
                  >
                    {status}
                  </li>
                );
              })}
            </ul>
          </section>
          <section className="grid gap-2">
            <h4>Inherent Risk</h4>
            <ul className="flex items-center gap-2">
              {["critical", "high", "medium", "low"].map((risk) => {
                return (
                  <li
                    key={risk}
                    className={`cursor-pointer capitalize ${
                      attributeColors[risk]
                    } dark:hover:bg-signin/30 duration-100 ${
                      inputs.inherent_risk === risk
                        ? "ring-2 ring-offset-2 ring-offset-blue-500"
                        : ""
                    }`}
                    onClick={() =>
                      setInputs({ ...inputs, inherent_risk: risk })
                    }
                  >
                    {risk}
                  </li>
                );
              })}
            </ul>
          </section>
          <section className="grid gap-2">
            <h4>Vendor Tier</h4>
            <ul className="flex items-center gap-2">
              {["critical", "high", "medium", "low"].map((tier) => {
                return (
                  <li
                    key={tier}
                    className={`cursor-pointer capitalize ${
                      attributeColors[tier]
                    } dark:hover:bg-signin/30 duration-100 ${
                      inputs.vendor_tier === tier
                        ? "ring-2 ring-offset-2 ring-offset-blue-500"
                        : ""
                    }`}
                    onClick={() => setInputs({ ...inputs, vendor_tier: tier })}
                  >
                    {tier}
                  </li>
                );
              })}
            </ul>
          </section>
          <section className="grid gap-2">
            <h4>Rank Tier</h4>
            <ul className="flex items-center gap-2">
              {["critical", "high", "medium", "low"].map((tier) => {
                return (
                  <li
                    key={tier}
                    className={`cursor-pointer capitalize ${
                      attributeColors[tier]
                    } dark:hover:bg-signin/30 duration-100 ${
                      inputs.rank_tier === tier
                        ? "ring-2 ring-offset-2 ring-offset-blue-500"
                        : ""
                    }`}
                    onClick={() => setInputs({ ...inputs, rank_tier: tier })}
                  >
                    {tier}
                  </li>
                );
              })}
            </ul>
          </section>
          <FileInput
            label="Logo (optional)"
            keyName="logo"
            types={["jpeg", "png", "svg"]}
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === "" || inputs.inherent_risk === ""}
              className="blue-button"
              onClick={() => {
                if (
                  vendors?.data.some(
                    (vendor: KeyStringVal) =>
                      vendor.name.toLowerCase().trim() ===
                      inputs.name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  addVendor.mutate(
                    {
                      info: {
                        name: inputs.name,
                        inherent_risk: inputs.inherent_risk,
                        ...(inputs.address !== "" && {
                          address: inputs.address,
                        }),
                        ...(inputs.contact_name !== "" && {
                          contact_name: inputs.contact_name,
                        }),
                        ...(inputs.contact_email !== "" && {
                          contact_email: inputs.contact_email,
                        }),
                        ...(inputs.contact_phone !== "" && {
                          contact_phone: inputs.contact_phone,
                        }),
                        ...(inputs.vendor_type !== "" && {
                          vendor_type: inputs.vendor_type,
                        }),
                        ...(inputs.vendor_manager !== "" && {
                          vendor_manager: inputs.vendor_manager,
                        }),
                        ...(inputs.vendor_tier !== "" && {
                          vendor_tier: inputs.vendor_tier,
                        }),
                        ...(inputs.rank_tier !== "" && {
                          rank_tier: inputs.rank_tier,
                        }),
                        ...(inputs.website !== "" && {
                          website: inputs.website,
                        }),
                        ...(inputs.duns_number !== "" && {
                          duns_number: inputs.duns_number,
                        }),
                        number_of_employees: numEmployees,
                        ...(inputs.status !== "" && { status: inputs.status }),
                        is_publicly_traded: inputs.is_publicly_traded,
                        ...(inputs.notes !== "" && { notes: inputs.notes }),
                      },
                    },
                    {
                      onSuccess: (data) => {
                        queryClient.invalidateQueries(["get-vendors"]);

                        if (inputs.logo) {
                          const vendorID = data?.slice(
                            data?.lastIndexOf(": ") + 2
                          );
                          const formData = new FormData();
                          formData.append("file", inputs.logo);
                          addVendorLogo.mutate({
                            vendorID: vendorID,
                            formData: formData,
                          });
                        }
                      },
                    }
                  );

                  handleOnClose();
                }
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ModalLayout
        showModal={addVendor.data !== undefined}
        onClose={handleOnCloseConfirmation}
      >
        <section className="grid content-start gap-5 py-4 h-full mx-auto">
          <article className="flex items-center gap-2">
            <img
              src="/general/checkmark.svg"
              alt="checkmark"
              className="w-6 h-6"
            />
            <h3 className="text-lg">New Vendor has been created!</h3>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default NewVendor;
