/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { showVariants } from "src/constants/general";
import { thirdPartyRiskTabs } from "src/constants/grc";
import { motion } from "framer-motion";
import PageLayout from "src/layouts/PageLayout";
import { useGRCStore } from "src/stores/grc";
import {
  faXmark,
  faMagnifyingGlass,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchGRC } from "src/services/grc";
import Vendors from "./Vendors/Vendors";
import SearchResults from "./SearchResults";
import Loader from "src/components/Loader/Loader";
import { checkAccountAdmin } from "../../utils/general";
import Assessments from "./Assessments/Assessments";

const ThirdPartyRisk = () => {
  const isAccountAdmin = checkAccountAdmin();

  const { GRCCategory, setGRCCategory, GRCQuery, setGRCQuery, GRCQueryOption } =
    useGRCStore();

  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("vendors");

  const search = SearchGRC();

  const type = selectedTab;

  const handleSearch = () => {
    if (GRCQuery && type && GRCQueryOption) {
      search.reset();
      search.mutate(
        {
          query: GRCQuery,
          type: type,
          searchType: GRCQueryOption,
        },
        {
          onSuccess: () => {
            sessionStorage.GRCCategory = "Third Party Risk";
          },
        }
      );
    }
  };

  useEffect(() => {
    if (
      search.data &&
      (GRCQuery === "" ||
        (GRCCategory !== "questions" &&
          selectedTab !== "all questions" &&
          !search.data[type]))
    )
      search.reset();
  }, [GRCQuery, GRCCategory, selectedTab]);

  useEffect(() => {
    sessionStorage.page = "Third Party Risk";
    if (thirdPartyRiskTabs.includes(sessionStorage.GRCCategory))
      setGRCCategory(sessionStorage.GRCCategory);
    else setGRCCategory("vendors");
    setGRCQuery("");
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-5 p-4 w-full h-full text-base overflow-auto scrollbar"
      >
        <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
          <nav className="flex flex-wrap items-center gap-1 p-1 dark:bg-gray-900 rounded-lg">
            {thirdPartyRiskTabs.map((tab) => {
              return (
                <button
                  key={tab}
                  className={`px-4 py-1 text-center capitalize ${
                    GRCCategory === tab
                      ? "dark:text-white dark:bg-gray-800"
                      : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                  } rounded-lg`}
                  onClick={() => {
                    sessionStorage.GRCCategory = tab;
                    setGRCCategory(tab);
                    if (tab === "vendors") setSelectedTab("vendors");
                    else if (tab === "questions")
                      setSelectedTab("all questions");
                    else if (tab === "assessment cycles")
                      setSelectedTab("assessment cycles");
                  }}
                >
                  {tab}
                </button>
              );
            })}
          </nav>
          <article className="flex items-center gap-5">
            {isAccountAdmin && (
              <a
                href="/settings/details?section=privacy%20review"
                className="text-base dark:hover:text-checkbox/80 duration-100"
              >
                <FontAwesomeIcon icon={faLink} /> Privacy Settings
              </a>
            )}
            <article className="flex items-center px-4 dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
              <span className="dark:text-white">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </span>
              <input
                type="input"
                autoComplete="off"
                spellCheck="false"
                placeholder="Search by Metadata"
                value={GRCQuery}
                onKeyUpCapture={(e) => {
                  if (e.key === "Enter" && GRCQuery !== "") handleSearch();
                }}
                onChange={(e) => setGRCQuery(e.target.value)}
                className="p-4 pr-12 w-[30rem] h-8 dark:bg-gray-800 focus:outline-none"
              />
              <article className="flex items-center gap-2 divide-x dark:divide-checkbox">
                {GRCQuery !== "" && (
                  <button
                    data-test="clear-query"
                    className="trash-button"
                    onClick={() => setGRCQuery("")}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                )}
              </article>
            </article>
          </article>
        </section>

        {search.status === "loading" ? (
          <Loader />
        ) : search.status === "success" ? (
          <SearchResults
            searchData={search.data}
            selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions}
            selectedTab={selectedTab}
          />
        ) : (
          <>
            {GRCCategory === "vendors" ? (
              <Vendors setSelectedTab={setSelectedTab} />
            ) : GRCCategory === "assessments" ? (
              <Assessments
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
              />
            ) : null}
          </>
        )}
      </motion.main>
    </PageLayout>
  );
};

export default ThirdPartyRisk;
