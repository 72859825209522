import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { RemoveGlobalQuestion } from "src/services/third-party-risk/questions/global-questions";

const RemoveQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
}: {
  selectedQuestions: string[];
  setSelectedQuestions: (selectedQuestions: string[]) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);

  const removeQuestion = RemoveGlobalQuestion();

  const deleting = removeQuestion.status === "loading";

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={deleting || selectedQuestions.length === 0}
        className="red-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        Delete All
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-5">
          <h4 className="text-base text-center mb-3">
            Are you sure you want to delete these questions?
          </h4>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={deleting}
              className="red-button"
              onClick={() => {
                removeQuestion.mutate({
                  questionIDs: selectedQuestions,
                });
                setSelectedQuestions([]);
              }}
            >
              Delete questions
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default RemoveQuestions;
