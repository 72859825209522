import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { convertToUTCString } from "src/utils/general";

const DropdownLayout: React.FC<{
  label?: string;
  value?: string | number;
  header?: boolean;
  color?: string;
  width?: string;
  placeholder?: string;
  timestamp?: boolean;
  showAbove?: boolean;
  selectedTextSize?: string;
  labelAbove?: boolean;
}> = ({
  label = "",
  value = "",
  header,
  color,
  width,
  placeholder,
  timestamp,
  showAbove,
  selectedTextSize,
  labelAbove,
  children,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <section
      className={`${labelAbove ? "grid" : "flex items-center"} gap-3 ${
        header ? "-mt-3" : ""
      } text-b2-reg`}
      onMouseLeave={() => setShowDropdown(false)}
    >
      {label && (
        <h4 className="capitalize max-w-60 dark:text-checkbox">{label}</h4>
      )}
      <article
        onClick={() => setShowDropdown(true)}
        className={`relative grid py-2 px-4 ${
          width ? width : "w-full"
        } text-left cursor-pointer ${
          color
            ? color
            : "dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100"
        } focus:outline-none rounded-t`}
      >
        <article className="flex items-center justify-between gap-2">
          <span className={`truncate ${selectedTextSize || ""}`}>
            {value === ""
              ? placeholder
                ? placeholder
                : "Select"
              : timestamp
              ? convertToUTCString(Number(value))
              : value}{" "}
          </span>
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article
            className={`absolute ${
              showAbove
                ? "bottom-8"
                : selectedTextSize === "text-lg"
                ? "top-11"
                : "top-8"
            }  left-0 grid content-start w-full max-h-36 ${
              color ? color : "dark:bg-gray-700"
            } focus:outline-none overflow-auto scrollbar rounded-b z-10`}
          >
            {children}
          </article>
        )}
      </article>
    </section>
  );
};

export default DropdownLayout;
