import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { AddGlobalQuestion } from "src/services/third-party-risk/questions/global-questions";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";

const AskGlobalQuestion = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    question: "",
  });

  const addQuestion = AddGlobalQuestion();

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="black-button"
        onClick={() => {
          setShow(true);
          setInputs({
            question: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add a Question</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add a Question
          </h3>
          <RegularInput
            label="Question"
            keyName="question"
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.question === ""}
              className="blue-button"
              onClick={() => {
                addQuestion.mutate({ question: inputs.question });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default AskGlobalQuestion;
