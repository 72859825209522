/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import VendorList from "./VendorList/VendorList";
import NewVendor from "./VendorList/NewVendor";

const Vendors = ({
  setSelectedTab,
}: {
  setSelectedTab: (selectedTab: string) => void;
}) => {
  useEffect(() => {
    setSelectedTab("vendors");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5 w-full">
      <NewVendor />
      <VendorList />
    </section>
  );
};

export default Vendors;
