import { motion } from "framer-motion";
import React, { useState } from "react";
import { validVariants } from "src/constants/general";

const RegularInput = ({
  label,
  keyName,
  inputs,
  setInputs,
  disabled,
  valid,
  setValid,
  required,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  disabled?: boolean;
  valid?: boolean;
  setValid?: (valid: boolean) => void;
  required?: boolean;
}) => {
  const [clicked, setClicked] = useState<boolean>(false);

  return (
    <article className="grid content-start gap-2 text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="text-red-500">*</span>}
      </header>
      <motion.input
        variants={validVariants}
        initial="hidden"
        animate={!valid && setValid ? "visible" : "hidden"}
        spellCheck="false"
        autoComplete="off"
        value={inputs[keyName]}
        disabled={disabled}
        placeholder="Please input"
        onClick={() => setClicked(false)}
        onBlur={() => setClicked(true)}
        onChange={(e) => {
          if (setValid) setValid(true);
          setInputs({
            ...inputs,
            [keyName]: e.target.value,
          });
        }}
        type="input"
        className={`px-4 py-2 w-full h-9 focus:outline-none dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent ${
          required && clicked && inputs[keyName] === ""
            ? "border dark:border-reset"
            : "dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500"
        } rounded`}
      />
      {setValid && !valid && (
        <span className="absolute -bottom-5 ml-0 text-left dark:text-reset font-light tracking-wider">
          {label} already exists
        </span>
      )}
    </article>
  );
};

export default RegularInput;
