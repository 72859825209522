/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import GlobalQuestions from "./GlobalQuestions/GlobalQuestions";
import CustomQuestionSets from "./CustomQuestionSets/CustomQuestionSets";
import AskGlobalQuestion from "./GlobalQuestions/AskGlobalQuestion";
import NewQuestionSet from "./GlobalQuestions/NewGlobalQuestionSet";

const Questions = ({
  selectedTab,
  setSelectedTab,
  selectedQuestions,
  setSelectedQuestions,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
  selectedQuestions: string[];
  setSelectedQuestions: (selectedQuestions: string[]) => void;
}) => {
  useEffect(() => {
    setSelectedTab("all questions");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center justify-between gap-10">
        <nav className="flex flex-wrap items-center">
          {["all questions", "question sets"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "all questions" ? (
          <article className="flex items-center gap-5">
            <AskGlobalQuestion />
            <NewQuestionSet />
          </article>
        ) : null}
      </header>
      {selectedTab === "all questions" ? (
        <GlobalQuestions
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      ) : (
        <CustomQuestionSets />
      )}
    </section>
  );
};

export default Questions;
