/* eslint-disable react-hooks/exhaustive-deps */
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { EditGlobalQuestion } from "src/services/third-party-risk/questions/global-questions";
import DeleteGlobalQuestion from "./DeleteGlobalQuestion";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import QuestionTags from "./QuestionTags";

const Response = ({
  qa,
  selectedQuestions,
  setSelectedQuestions,
  filteredMetadata,
}: {
  qa: any;
  selectedQuestions: any;
  setSelectedQuestions: any;
  filteredMetadata: any;
}) => {
  const questionID = qa.generated_id;
  const [editedQuestion, setEditedQuestion] = useState<string>(qa.question);
  const [editedAnswer, setEditedAnswer] = useState<string>(qa.response);
  const [show, setShow] = useState<boolean>(false);

  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: questionID,
    });

  const editResponse = EditGlobalQuestion(questionID);

  const handleDiscardEditedQuestion = () => setEditedQuestion(qa.question);
  const handleDiscardEditedAnswer = () => setEditedAnswer(qa.response);

  const selected = selectedQuestions.includes(questionID);

  useEffect(() => {
    handleDiscardEditedQuestion();
    handleDiscardEditedAnswer();
  }, [qa]);

  return (
    <tr
      data-test="table-row"
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="relative p-5 gap-3 w-full break-words dark:bg-gray-800"
    >
      {filteredMetadata?.map((col: string, colIndex: number) => {
        return (
          <td
            key={`${questionID}-${colIndex}`}
            className="relative px-6 py-3 last:pr-16 text-left"
          >
            <article className="flex items-start gap-2">
              {colIndex === 0 && (
                <article className="flex items-center gap-2 mt-2">
                  <button className="hover:cursor-grab">
                    <FontAwesomeIcon
                      icon={faGripVertical}
                      className="dark:text-gray-500"
                    />
                  </button>
                  <input
                    type="checkbox"
                    checked={selected}
                    onChange={() => {
                      if (selected)
                        setSelectedQuestions(
                          selectedQuestions.filter(
                            (curQuestionID: string) =>
                              curQuestionID !== questionID
                          )
                        );
                      else
                        setSelectedQuestions([
                          ...selectedQuestions,
                          questionID,
                        ]);
                    }}
                    className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-checkbox dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
                  />
                </article>
              )}
              {col === "question" ? (
                <article className="grid content-start gap-3 w-full">
                  <textarea
                    value={editedQuestion}
                    onChange={(e) => setEditedQuestion(e.target.value)}
                    className="px-4 py-2 w-full h-24 border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none overflow-auto scrollbar rounded"
                  />
                  {qa.question !== editedQuestion && (
                    <article className="flex items-center justify-self-end gap-2">
                      <button
                        className="red-button"
                        onClick={handleDiscardEditedQuestion}
                      >
                        Discard
                      </button>
                      <button
                        className="blue-button"
                        onClick={() => {
                          editResponse.mutate({
                            question: editedQuestion,
                            response: editedAnswer,
                          });
                          handleDiscardEditedQuestion();
                        }}
                      >
                        Save
                      </button>
                    </article>
                  )}
                </article>
              ) : col === "response" ? (
                <article className="grid content-start gap-3 w-full">
                  <textarea
                    value={editedAnswer}
                    onChange={(e) => setEditedAnswer(e.target.value)}
                    className={`px-4 py-2 w-full ${
                      show
                        ? "h-40 overflow-auto scrollbar"
                        : "h-20 overflow-hidden"
                    } border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded`}
                  />
                  {qa.response !== editedAnswer && (
                    <article className="flex items-center justify-self-end gap-2">
                      <button
                        className="red-button"
                        onClick={handleDiscardEditedAnswer}
                      >
                        Discard
                      </button>
                      <button
                        className="blue-button"
                        onClick={() => {
                          editResponse.mutate({
                            question: editedQuestion,
                            response: editedAnswer,
                          });
                          handleDiscardEditedAnswer();
                        }}
                      >
                        Save
                      </button>
                    </article>
                  )}
                  {qa.response && (
                    <button
                      className="w-max underline dark:text-blue-400 dark:hover:text-blue-400/70 duration-100"
                      onClick={() => setShow(!show)}
                    >
                      {show ? "Hide" : "Show"}
                    </button>
                  )}
                </article>
              ) : col === "tags" ? (
                <QuestionTags questionID={questionID} tags={qa.tags} />
              ) : null}
              {colIndex === filteredMetadata.length - 1 && (
                <DeleteGlobalQuestion questionID={questionID} />
              )}
            </article>
          </td>
        );
      })}
    </tr>
  );
};

export default Response;
