import React from "react";
import { convertToUTCShortString } from "src/utils/general";
import { attributeColors } from "src/constants/general";
import { GetVendorMetadata } from "src/services/third-party-risk/vendors/vendors";
import UpdateVendor from "../UpdateVendor";
import VendorGroupFilter from "src/components/Filter/ThirdPartyRisk/VendorGroupFilter";
import ExportResponses from "./DocumentList/VendorResponseList/ExportResponses";
import VendorTags from "../VendorTags";

const VendorMetadata = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string | undefined;
}) => {
  const { data: vendorMetadata } = GetVendorMetadata(vendorID);

  return (
    <>
      {vendorMetadata && (
        <header className="grid gap-3">
          {localStorage.assessmentCycle && assessmentID && (
            <h4>Assessment: {localStorage.assessmentCycle}</h4>
          )}
          <VendorGroupFilter label="Vendor Group" vendor={vendorMetadata} />
          <article className="flex flex-wrap items-center justify-between gap-5 pb-1 border-b-1 dark:border-white">
            <header className="flex items-center gap-5">
              <h2 className="text-2xl">{vendorMetadata.name}</h2>
              <span
                className={`capitalize ${
                  attributeColors[vendorMetadata.inherent_risk?.toLowerCase()]
                }`}
              >
                {vendorMetadata.inherent_risk}
              </span>
            </header>
            <article className="flex flex-wrap items-center gap-3 pb-1">
              <article className="flex items-center gap-2">
                <h4 className="dark:text-checkbox">last updated at</h4>
                <span>
                  {convertToUTCShortString(vendorMetadata.last_updated)}
                </span>
              </article>
              <UpdateVendor vendorID={vendorID} vendor={vendorMetadata} />
            </article>
          </article>
          <article className="flex items-center justify-between gap-10">
            <article className="flex items-center gap-2 divide-x dark:divide-white">
              {vendorMetadata.address && (
                <p className="italic text-sm">{vendorMetadata.address}</p>
              )}
              <article className="flex items-center gap-2">
                {vendorMetadata.contact && (
                  <article className="pl-2 font-extralight">
                    {vendorMetadata.contact.name}{" "}
                    {vendorMetadata.contact.email && ","}{" "}
                    {vendorMetadata.contact.email}{" "}
                    {vendorMetadata.contact.phone && ","}
                    {vendorMetadata.contact.phone}
                  </article>
                )}
              </article>
            </article>
            <ExportResponses vendorID={vendorID} assessmentID={assessmentID} />
          </article>
          <VendorTags vendorID={vendorID} tags={vendorMetadata.tags} />
        </header>
      )}
    </>
  );
};

export default VendorMetadata;
