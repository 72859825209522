/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCheck,
  faChevronCircleDown,
  faChevronCircleRight,
  faComment,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { GetControlEvidence } from "src/services/audit-management";
import EvidenceDetail from "./EvidenceDetail";
import AttachEvidence from "./AttachEvidence";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import {
  GetEvidenceReport,
  UploadEvidenceReport,
} from "../../../../../services/audit-management";
import UserInput from "../../../../../components/Input/UserInput";
import Analysis from "./Analysis";
import { motion } from "framer-motion";
import { useGRCStore } from "src/stores/grc";
import { GetGRCDiscussionCount } from "../../../../../services/grc";
import MultiUserInput from "../../../../../components/Input/MultiUserInput";

const EvidenceList = ({
  auditID,
  controlID,
}: {
  auditID: string;
  controlID: string;
}) => {
  const {
    showGRCPanel,
    setShowGRCPanel,
    setSelectedAnchorID,
    setSelectedGRCPanelTab,
  } = useGRCStore();

  const [saved, setSaved] = useState<boolean>(false);
  const [show, setShow] = useState<string>("");
  const [inputs, setInputs] = useState({
    exclude: false,
    testing_procedure: "",
    instructions: "",
    exclude_notes: "",
    users: [],
    due_date: Date.now() * 1000,
  });

  const { data: evidenceList } = GetControlEvidence(
    auditID,
    controlID,
    show === controlID
  );
  const { data: evidenceReport, status: evidenceReportStatus } =
    GetEvidenceReport(auditID, controlID, show === controlID);
  const uploadEvidenceReport = UploadEvidenceReport(auditID, controlID);
  const { data: discussionCounts } = GetGRCDiscussionCount(auditID);

  const discussionCount =
    discussionCounts?.find((anchor) => anchor.anchor_id === controlID)?.count ||
    0;

  const handleSaveEvidenceReport = () => {
    uploadEvidenceReport.mutate(
      {
        report: {
          exclude: inputs.exclude,
          ...(inputs.testing_procedure && {
            testing_procedure: inputs.testing_procedure,
          }),
          ...(inputs.instructions && {
            instructions: inputs.instructions,
          }),
          ...(inputs.exclude_notes && {
            exclude_notes: inputs.exclude_notes,
          }),
          users: inputs.users.length > 0 ? inputs.users : null,
          due_date: Date.now() * 1000,
        },
      },
      {
        onSuccess: () => {
          setSaved(true);
          setTimeout(() => setSaved(false), 5000);
        },
      }
    );
  };

  useEffect(() => {
    if (evidenceReportStatus === "success") {
      if (evidenceReport)
        setInputs({
          exclude: evidenceReport.exclude,
          testing_procedure: evidenceReport.testing_procedure || "",
          instructions: evidenceReport.instructions || "",
          exclude_notes: evidenceReport.exclude_notes || "",
          users: evidenceReport.users || "",
          due_date: evidenceReport.due_date || Date.now() * 1000,
        });
      else
        setInputs({
          exclude: false,
          testing_procedure: "",
          instructions: "",
          exclude_notes: "",
          users: [],
          due_date: Date.now() * 1000,
        });
    }
  }, [evidenceReport]);

  return (
    <>
      <Disclosure>
        {({ open }) => {
          return (
            <section className="text-sm">
              <Disclosure.Button
                className="flex items-center gap-2"
                onClick={() => {
                  if (show !== "") setShow("");
                  else setShow(controlID);
                }}
              >
                <FontAwesomeIcon
                  icon={open ? faChevronCircleDown : faChevronCircleRight}
                  className="dark:text-black"
                />
                <h4>Evidence</h4>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <section className="flex flex-col flex-grow gap-2 mt-3 mx-6">
                    <article className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={inputs.exclude}
                        onClick={() =>
                          setInputs({
                            exclude: !inputs.exclude,
                            testing_procedure: "",
                            instructions: "",
                            exclude_notes: "",
                            users: [],
                            due_date: Date.now() * 1000,
                          })
                        }
                        className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-black dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
                      />
                      <label className="w-max">Exclude</label>
                    </article>
                    {!inputs.exclude ? (
                      <article className="flex flex-col flex-grow gap-2">
                        <article className="flex items-center gap-5">
                          <MultiUserInput
                            label="Assigned to"
                            keyName="users"
                            inputs={inputs}
                            setInputs={setInputs}
                            allowUnselect
                          />
                          <article className="flex items-center gap-5">
                            {["Discussion"].map((tab) => {
                              return (
                                <button
                                  key={tab}
                                  className="flex items-center gap-1 text-xs"
                                  onClick={() => {
                                    setShowGRCPanel(!showGRCPanel);
                                    setSelectedGRCPanelTab(tab);
                                    setSelectedAnchorID(controlID);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      tab === "Notes" ? faNoteSticky : faComment
                                    }
                                    className="p-1 w-3 h-3 dark:text-black dark:bg-white rounded-full"
                                  />
                                  <span>{discussionCount}</span>
                                  {tab}
                                </button>
                              );
                            })}
                          </article>
                        </article>
                        <article className="flex flex-col flex-grow gap-1">
                          <h4>Testing Procedure</h4>
                          <article className="flex items-center gap-5">
                            {["inquiry", "observation", "inspection"].map(
                              (procedure) => {
                                return (
                                  <article
                                    key={procedure}
                                    className="flex items-center gap-2"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        inputs.testing_procedure === procedure
                                      }
                                      onClick={() =>
                                        setInputs({
                                          ...inputs,
                                          testing_procedure: procedure,
                                        })
                                      }
                                      className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-black dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
                                    />
                                    <label className="w-max capitalize">
                                      {procedure}
                                    </label>
                                  </article>
                                );
                              }
                            )}
                          </article>
                        </article>
                        <article className="flex flex-col flex-grow gap-1">
                          <h4>Notes</h4>
                          <textarea
                            value={inputs.instructions}
                            onChange={(e) =>
                              setInputs({
                                ...inputs,
                                instructions: e.target.value,
                              })
                            }
                            className="px-4 py-1 pb-10 w-full text-sm dark:bg-black focus:outline-none dark:focus:ring-0 dark:focus:border-transparent resize-none overflow-auto scrollbar"
                          />
                        </article>
                        <GeneralSnapshotDatepicker
                          label="Due Date"
                          keyName="due_date"
                          inputs={inputs}
                          setInputs={setInputs}
                        />

                        <AttachEvidence
                          auditID={auditID}
                          controlID={controlID}
                        />

                        {evidenceList?.length > 0 && (
                          <ul className="flex flex-wrap gap-2">
                            {evidenceList.map((evidence: any) => {
                              return (
                                <EvidenceDetail
                                  key={evidence.evidence_id}
                                  auditID={auditID}
                                  controlID={controlID}
                                  evidence={evidence}
                                />
                              );
                            })}
                          </ul>
                        )}
                      </article>
                    ) : (
                      <article className="flex flex-col flex-grow gap-1">
                        <h4>Notes</h4>
                        <textarea
                          value={inputs.exclude_notes}
                          onChange={(e) =>
                            setInputs({
                              ...inputs,
                              exclude_notes: e.target.value,
                            })
                          }
                          className="px-4 py-1 pb-10 w-full text-sm dark:bg-black focus:outline-none dark:focus:ring-0 dark:focus:border-transparent resize-none overflow-auto scrollbar"
                        />
                      </article>
                    )}

                    <button
                      className="px-4 py-1 w-max green-gradient-button"
                      onClick={handleSaveEvidenceReport}
                    >
                      Save
                    </button>
                    {saved && (
                      <motion.article
                        initial={{ y: 3, opacity: 0 }}
                        animate={{
                          y: 0,
                          opacity: 1,
                          transition: { duration: 0.3 },
                        }}
                        className="flex items-center gap-2 text-xs"
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="w-3 h-3 dark:text-contact"
                        />
                        successfully saved
                      </motion.article>
                    )}
                    {!inputs.exclude && (
                      <Analysis auditID={auditID} controlID={controlID} />
                    )}
                  </section>
                </Disclosure.Panel>
              </Transition>
            </section>
          );
        }}
      </Disclosure>
    </>
  );
};

export default EvidenceList;
