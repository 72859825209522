import React from "react";
import { GetAssessmentCycleMetadata } from "src/services/third-party-risk/assessments/assessment-cycles";
import { convertToUTCShortString } from "../../../../../utils/general";
import { attributeColors } from "../../../../../constants/general";
import { AssessVendors } from "../../../../../services/third-party-risk/assessments/assessment-cycles";

const ReviewMetadata = ({ assessmentID }: { assessmentID: string }) => {
  const { data: assessmentMetadata } = GetAssessmentCycleMetadata(assessmentID);
  const accessVendors = AssessVendors(assessmentID);

  return (
    <header className="grid gap-2">
      {assessmentMetadata && (
        <>
          <article className="flex flex-wrap items-center justify-between gap-20 pb-2 border-b-1 dark:border-white">
            <h2 className="text-2xl">{assessmentMetadata.title}</h2>
            <article className="flex flex-wrap items-center gap-3">
              {convertToUTCShortString(assessmentMetadata.start_date)} -{" "}
              {convertToUTCShortString(assessmentMetadata.end_date)}
              <span
                className={`${attributeColors[assessmentMetadata.is_open]}`}
              >
                {assessmentMetadata.is_open ? "OPEN" : "CLOSED"}
              </span>
            </article>
          </article>
          <p>{assessmentMetadata.purpose}</p>
        </>
      )}
      <button
        className="place-self-end blue-button"
        onClick={() => accessVendors.mutate({})}
      >
        Assess Vendors
      </button>
    </header>
  );
};

export default ReviewMetadata;
